// extracted by mini-css-extract-plugin
export var base = "MarketingFreemiumOverviewPage-module--base--TE1Ub";
export var rightAlignedContainer = "MarketingFreemiumOverviewPage-module--rightAlignedContainer--3lIGT";
export var rightAlignedContainerSmall = "MarketingFreemiumOverviewPage-module--rightAlignedContainerSmall--3EhHW";
export var leftAlignedContainer = "MarketingFreemiumOverviewPage-module--leftAlignedContainer--3Rh4X";
export var blogContainer = "MarketingFreemiumOverviewPage-module--blogContainer--3WWvS MarketingFreemiumOverviewPage-module--base--TE1Ub";
export var container = "MarketingFreemiumOverviewPage-module--container--3UReT MarketingFreemiumOverviewPage-module--base--TE1Ub";
export var largeContainer = "MarketingFreemiumOverviewPage-module--largeContainer--3NX0m MarketingFreemiumOverviewPage-module--base--TE1Ub";
export var mobileSmallPadding = "MarketingFreemiumOverviewPage-module--mobileSmallPadding--297ea";
export var fullWidthMobile = "MarketingFreemiumOverviewPage-module--fullWidthMobile--1wT2h";
export var gridWithSidebar = "MarketingFreemiumOverviewPage-module--gridWithSidebar--1v6qZ";
export var noMaxWidth = "MarketingFreemiumOverviewPage-module--noMaxWidth--hv_E0";
export var hero = "MarketingFreemiumOverviewPage-module--hero--3feaw";
export var copyLockupListValueProps = "MarketingFreemiumOverviewPage-module--copyLockupListValueProps--34LDl";
export var featuredTemplates = "MarketingFreemiumOverviewPage-module--featuredTemplates--2tXPd";
export var copyLockupListFeatures = "MarketingFreemiumOverviewPage-module--copyLockupListFeatures--Jl8Ou";
export var howToOrderedList = "MarketingFreemiumOverviewPage-module--howToOrderedList--1Mq-e";
export var community = "MarketingFreemiumOverviewPage-module--community--16xQ0";