import '../../utils/marketingPageDataFragment';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { AnimotoLayout } from '../../components/AnimotoLayout';
import CommunityModule, { CommunityDataPropsObject } from '../../components/CommunityModule';
import CopyLockupListModule, { CopyLockupListDataPropsObject } from '../../components/CopyLockupListModule';
import FeaturedTemplatesModule, { FeaturedTemplatesDataPropsObject } from '../../components/FeaturedTemplatesModule';
import HeroModule, { HeroDataPropsObject } from '../../components/HeroModule';
import HowToOrderedListModule, { HowToOrderedListDataPropsObject } from '../../components/HowToOrderedListModule';
import StyledSectionBackground from '../../components/StyledSectionBackground';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import getSeoInfo from '../../utils/getSeoInfoForMarketingPage';
import {
  community as communityClass,
  copyLockupListFeatures,
  copyLockupListValueProps,
  featuredTemplates as featuredTemplatesClass,
  hero as heroClass,
  howToOrderedList as howToOrderedListClass
} from './MarketingFreemiumOverviewPage.module.css';

export default function MarketingFreemiumOverviewPage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    showSiteNav
  } = page;

  const {
    community,
    copyLockupList,
    featuredTemplates,
    hero,
    howToOrderedList
  } = componentsQueryResultToData(components);

  const SEOInfo = getSeoInfo(page, description, canonicalUrlPage, imageFieldName, hero);

  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      <HeroModule className={heroClass} data={hero} />
      <CopyLockupListModule childrenLayout="centered" className={copyLockupListValueProps} data={copyLockupList[0]} />
      <StyledSectionBackground className={featuredTemplatesClass} hasBackgroundColor withBlobs>
        <FeaturedTemplatesModule data={featuredTemplates} />
      </StyledSectionBackground>
      <CopyLockupListModule childrenCollapseOnMobile childrenLayout="fullWidthCopy" className={copyLockupListFeatures} data={copyLockupList[1]} />
      <HowToOrderedListModule className={howToOrderedListClass} data={howToOrderedList} />
      <CommunityModule className={communityClass} data={community} />
    </AnimotoLayout>
  );
}

export const pageQuery = graphql`
  query MarketingFreemiumOverviewPageByUrl($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageData
    }
  }
`;

MarketingFreemiumOverviewPage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCommunity']),
          ...CommunityDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCopyLockupList']),
          ...CopyLockupListDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentFeaturedTemplates']),
          ...FeaturedTemplatesDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHero']),
          ...HeroDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHowToOrderedList']),
          ...HowToOrderedListDataPropsObject
        })
      ])).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
